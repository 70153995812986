import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import Content from 'components/Content'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const Aanbod = styled.div``

const Image = styled(Plaatjie)`
  margin-bottom: 2rem;
  & img {
    height: 150px !important;
    width: 100% !important;
    object-fit: contain !important;
  }
`

const Labels = ({ data: { page }, pageContext }) => (
  <Layout>
    <SEO seo={page.seo} />
    <Aanbod>
      <CustomBreadCrumb
        data={page}
        className="py-2"
        pageContext={pageContext}
      />
      <div className="container">
        <div className="row py-5">
          {page.templateLabels.inhoudDefault.labels.map((label, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="card h-100 p-4 align-items-start">
                <Image image={label.image} />
                <div>
                  <h5 className="card-title">{label.title}</h5>
                  <Content content={label.description} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Aanbod>
  </Layout>
)

export default Labels

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      uri
      templateLabels {
        inhoudDefault {
          labels {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 800)
                }
              }
            }
            description
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
